@font-face {
    font-family: "Maax";
    src: url("./Maax/Maax-Bold-205TF.ttf") format("truetype"),
      url("./Maax/Maax-Bold-205TF.woff2") format("woff2");
    font-weight: 700;
  }
  @font-face {
    font-family: "Maax";
    src: url("./Maax/Maax-Light-205TF.ttf") format("truetype"),
      url("./Maax/Maax-Light-205TF.woff2") format("woff2");
    font-weight: 300;
  }
  @font-face {
    font-family: "Maax";
    src: url("./Maax/Maax-Italic-205TF.ttf") format("truetype"),
      url("./Maax/Maax-Italic-205TF.woff2") format("woff2");
    font-style: italic;
    font-weight: 400;
  }
  @font-face {
    font-family: "Maax";
    src: url("./Maax/Maax-Medium-205TF.ttf") format("truetype"),
      url("./Maax/Maax-Medium-205TF.woff2") format("woff2");
    font-weight: 500;
  }
  @font-face {
    font-family: "Maax";
    src: url("./Maax/Maax-Medium-Italic-205TF.ttf")
        format("truetype"),
      url("./Maax/Maax-Medium-Italic-205TF.woff2") format("woff2");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "Maax";
    src: url("./Maax/Maax-Regular-205TF.ttf") format("truetype"),
      url("./Maax/Maax-Regular-205TF.woff2") format("woff2");
    font-weight: 400;
  }
  @font-face {
    font-family: "Maax";
    src: url("./Maax/Maax-Black-205TF.ttf") format("truetype"),
      url("./Maax/Maax-Black-205TF.woff2") format("woff2");
    font-weight: 800;
  }