@import "../assets/fonts/fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

.small-right-shadow {
  box-shadow: 0px 10px 20px 0px #00adbb12;
}

@media only screen and (min-width: 720px) {
  .calculated-fullheight {
    height: calc(100vh - 64px);
  }
}

@media only screen and (max-width: 719px) {
  .calculated-fullheight {
    min-height: 100vh;
    height: 100vh;
  }
}

.yellow-button {
  @apply flex w-full items-center justify-center rounded-lg bg-yellow py-4;
}

.gray-button {
  @apply flex w-full items-center justify-center rounded-lg bg-[#ededed] opacity-90 hover:opacity-100 py-4;
}


* {
  @apply text-blackText;
  font-size: inherit;
}

.search-field {
  @apply h-[40px] rounded-lg p-3;
}

.payment-card-shadow {
  box-shadow: 0px 6px 32px 0px #616f741a;
}

.center-items {
  @apply flex items-center justify-center;
}

.metamask-gradient-background {
  background: linear-gradient(180deg, #e1e9f0 0%, rgba(225, 233, 240, 0) 100%);
}

.modal-shadow {
  box-shadow: 0px -15px 20px 0px #00adbb0d;
  box-shadow: 0px -2px 4px 0px #00adbb12;
}

.slippage-item-shadow {
  box-shadow: 0px 6px 32px 0px #616f741a;
}

.success-gradient {
  background: linear-gradient(0deg, #00e4c4, #00e4c4),
    linear-gradient(136.78deg, #1ae5bb 12.36%, #1eb596 87.03%);
}

.failed-gradient {
  background: linear-gradient(136.78deg, #fd9ca0 12.36%, #eb545a 87.03%),
    linear-gradient(0deg, #ffc9cb, #ffc9cb);
}

.retry-shadow {
  box-shadow: 0px 10px 20px 0px #00adbb12;
}

.loader {
  border: 13px solid #f3f3f3;
  border-top: 13px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.5s linear infinite;
}

.small-loader {
  border: 0px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.option-disabled {
  filter: grayscale(40);
  pointer-events: none;
  cursor: not-allowed;
}
